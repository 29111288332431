body {
  font-family: 'Lato';
  color: #505055;
  line-height: 1.7rem;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat';
  font-weight: 500;
  text-transform: uppercase;
}

.btn {
  color: #fff;
  font-size: 14px;
  font-family: 'Montserrat';
  padding: .75rem 2.5rem;
  border-radius: 0.2rem;

  &:hover {
    color: #fff;
  }
}

.nav-link, .btn, .owl-nav div, .owl-item .item img {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

/* TODO moze dać do style.php */
a {
  color: #39a1f1;
}

.header h5 {
  font-size: 16px;
}

#nav  {
  padding: 0;
}

#navbarNav  {
  background: #253a95;
  background: -moz-linear-gradient(top, #253a95 0%, #09195e 100%);
  background: -webkit-linear-gradient(top, #253a95 0%,#09195e 100%);
  background: linear-gradient(to bottom, #253a95 0%,#09195e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#253a95', endColorstr='#09195e',GradientType=0 );

  .menu {

    .active, .nav-item:hover {

      .nav-link {
        background: #3950b3;
        background: -moz-linear-gradient(top, #3950b3 0%, #1c3188 100%);
        background: -webkit-linear-gradient(top, #3950b3 0%,#1c3188 100%);
        background: linear-gradient(to bottom, #3950b3 0%,#1c3188 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3950b3', endColorstr='#1c3188',GradientType=0 );
      }

    }

    .nav-link {
      text-transform: uppercase;
      font-size: 0.90rem;
      font-family: 'Montserrat';
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      color: #fff;
    }

    > li > .nav-link {
      //width: 100%;
    }

    .parent.current{
      position: relative;
    }
  }

  .dropdown-menu  {
    padding: 0;
  }

  .dropdown-item {
    padding-left: 0;
    padding-right: 0;
  }

  .fa   {
    position: relative;

    &:before {
      content: "\f005";
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      padding-right: 10px;
      font-size: 1.1rem;
      font-weight: lighter;
    }
  }

  .nav-item-id-2  {
    .fa:before   {
      content: "\f015";
    }
  }
  .nav-item-id-12  {
    .fa:before   {
      content: "\f03e";
      font-size: 1.0rem;
    }
  }
  .nav-item-id-3  {
    .fa:before   {
      content: "\f019";
    }
  }
  .nav-item-id-6  {
    .fa:before   {
      content: "\f095";
    }
  }
  .nav-item-id-5  {
    .fa:before   {
      content: "\f07a";
    }

    .nav-link {

      background: #e34147;
      background: -moz-linear-gradient(top, #e34147 0%, #c4171d 100%);
      background: -webkit-linear-gradient(top, #e34147 0%,#c4171d 100%);
      background: linear-gradient(to bottom, #e34147 0%,#c4171d 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e34147', endColorstr='#c4171d',GradientType=0 );
    }
  }

}

.navbar {
  .menu {
   width: 100%;
    //margin-left: auto;

  }

  .links  {
    color: #7d7d7d;
    margin-top: 0.5rem;
    margin-bottom: 0.6rem;

    .fa   {
      padding-right: 10px;
    }

    a   {
      color: inherit;
    }

    p   {
      margin-bottom: 0;
    }

    .list-inline-item:first-child {
      margin-right: 2rem;
    }

  }
}
/*
.dropdown:hover>.dropdown-menu {
  display: block;
}
*/
#langs {
  margin-left: 1rem;

  li {
    display: inline-block;
    margin-right: 0.4rem;

  }
  .flag-icon {
    border: 1px solid #cecece;
    line-height: 0.9rem;

    :before {
    }
  }
  .lang-code  {
    display: none;
  }
}

.dropdown .dropdown-menu {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;

  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
}

.dropdown:hover .dropdown-menu {
  max-height: 200px;
  opacity: 1;
}

//.navbar-expand-lg .navbar-nav .dropdown-menu  {
//  display: block;
//}



.under-slider  {
  background-color: #f7f8fa;
  padding: 1.0rem 0 0.8rem 0;
  border-bottom: 1px solid #d9dee2;

  .section-inner  {
    display: block;
    justify-content: space-between;
    align-items: center;

    p   {
      margin-bottom: 0;
    }

    .btn {
      margin: 0.2rem 0.4rem;
    }
  }

  .under-slider-text  {
    margin-bottom: 1rem;
  }
}
/**
  Columny
 */
.page-sidebar-column  {

  #content  {
    width: 100%;
    display: inline-block;
    float: left;
  }

  #sidebar-column {
    width: 100%;
    display: inline-block;

    .box-title  {
      font-size: 28px;
      font-weight: 600;
      padding-bottom: 2rem;
      background: url("../images/titlewrap_border.jpg") no-repeat left bottom;
    }

    .box-1  {
      .box-title  {
        margin-bottom: 2.6rem;
      }
    }

    .box ul {
      padding-left: 0;
      list-style: none;

      li  {
        font-size: 15px;
        line-height: 1.4rem;
        position: relative;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ededed;
        //text-indent: 1em;
        padding-left: 2em;

        &:before  {
          content: "\2022";
          position: absolute;
          top: 0;
          left: 1em;
          //content: "";
          color: #cc0001;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -.5em;
          padding-right: 1.0em;
        }

        &:last-child  {
          border-bottom: none;
        }
      }
    }

    .btn  {
      background: #e44248;
      background: -moz-linear-gradient(top, #e44248 14%, #c4161c 98%);
      background: -webkit-linear-gradient(top, #e44248 14%,#c4161c 98%);
      background: linear-gradient(to bottom, #e44248 14%,#c4161c 98%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e44248', endColorstr='#c4161c',GradientType=0 );
      color: #fff;
      border-radius: 0;
      font-size: 14px;
      margin-top: 2.4rem;
      border: 0;
      padding: 0.6rem 1rem;

      &:hover  {
        background: #cd4147;
        background: -moz-linear-gradient(top, #cd4147 14%, #af161c 98%);
        background: -webkit-linear-gradient(top, #cd4147 14%,#af161c 98%);
        background: linear-gradient(to bottom, #cd4147 14%,#af161c 98%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cd4147', endColorstr='#af161c',GradientType=0 );

      }
    }



  }
}
.sidebar-column-left  {

  #sidebar-column {
    margin-left: 0;
    margin-right: 5%;
  }
}

#main {
  padding: 5rem 3rem 3rem 3rem;
  background-color: #fff;

  p {
    color: #8e8e90;
    //padding-bottom: 0.5rem;
  }

  .content {

    h2 {
      position: relative;
      padding-bottom: 2.0rem;

      &:after {
        position: absolute;
        bottom: 0.9rem;
        left: 0;
        width: 20%;
        height: 0.25rem;
        content: "";
      }
    }
  }
}

.page #main {
  padding-top: 5rem;

  > .row  {
    margin: 0;
  }
}

/**
#parallax
 */
#parallax {
  background-position: center;
  color: #fff;
  position: relative;

  .container {
    padding: 4rem 0 3rem 0;
    position: relative;
  }

  &:before  {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0.7;
    background-color: #d99777;
  }
}

.bg-gray  {
  background-color: #eef0f3;
}

.section-home-contact-data  {
  font-size: 18px;

  .itp-col {
    padding: 1rem 1rem 0.5rem 1rem;
  }

  a {
    color: inherit;
  }
}

.parallax-window {
  min-height: 250px;
  background: transparent;
}

/**
sidebar
 */
.sidebar  {
  padding-left: 0;

  .box  {
    list-style: none;
    margin-bottom: 2rem;
  }

  .box-title  {
    margin-bottom: 1rem; // nie wiem do czego to było
    font-size: 1.4rem;
  }
}

#footer {
  background: #cc0001;
  color: #fff;

  .footer-column  {
    padding: 5rem 0 1rem 0;

    .box-title  {
      color: #ffffff;
      font-size: 1.6rem;
      margin-bottom: 3rem;
    }

  }

  #footer-foo {
    border-top: 1px solid #7d7d7e;
    font-weight: 300;
    text-align: center;
    padding: 3rem 0 1rem 0;

  }

  a   {
    color: inherit;

    &:hover {
      color: #fff;
    }
  }

  #copy {
    margin-top: 1rem;
    font-size: 0.95rem;
  }

  #design-by  {
    margin-top: 1rem;
    font-size: 0.95rem;
  }
}

/**
#cookies
 */
#cookies  {
  background-color: #253a95;
  padding: 20px 0 10px 0;
  position: fixed;
  bottom: 0;
  color: #ddd;
  z-index: 99;
  display: none;

  .container-fluid {
    max-height: 180px;
    overflow: auto;
  }

  .closecookies {
    float: right;
    max-width: 200px;
    font-size: 11px;
    white-space: normal;
    padding: .5rem 0.5rem;
    margin-left: 1rem;
    border-color: #ddd;

    &:hover {
      background-color: #efefef;
      color: #212121;
    }
  }

  p   {
   font-size: 13px;
    line-height: 1rem;
  }
}

/**
  Facebook
 */
#slide-box-fblike {cursor: pointer; position: fixed; right: -326px; top: 126px; z-index: 15;}
.no-touch #slide-box-fblike {transition: right 0.4s ease-out 0s, z-index 0.01s ease-in 0.4s;}
.no-touch #slide-box-fblike:hover {right: 0; transition: right 0.4s ease-out 0s, z-index 0.01s ease-in 0s; z-index: 15;}
#slide-box-fblike .inside {background: none repeat scroll 0 0 #FFFFFF; border: 2px solid #39579B; height: 250px; padding: 10px; position: relative; width: 326px; z-index: 2;}
#slide-box-fblike .belt {background: url("../images/bt_social.png") repeat scroll 0 0 #3B5999; border-radius: 3px 0 0 3px; color: #FFFFFF; font-family: Verdana; font-size: 1px; font-weight: bold; height: 49px; left: -47px; position: absolute; top: 0; width: 47px; z-index: 0;}
#slide-box-fblike:hover .belt {background-position: 0 -49px;}

/**
.form-wrap
 */
.form-wrap  {

  .form-control {
    margin-bottom: 0.6rem;
    font-size: 0.9rem;
    padding-top: 0.4rem ;
    padding-bottom: 0.4rem ;
  }

  #captcha  {
    //margin-bottom: 0.6rem;
    max-height: 2.37rem;
    border-radius: 0.25rem;
    width: 100%;
  }
}

/**
#slider-wrap-20
 */
.carousel-item-background-image {
  //height: 100vh;
  min-height: 40vh;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.layer-mask {
  background-color: rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.carousel-control {
  background-color: rgba(255,255,255,0.2);
  padding: 6px 18px;
}

.carousel-caption {
  top: 0;
  text-align: left;
  padding-bottom: 4rem;
  left: 0;
  right: 0;
}

.carousel .desc-wrap  {

  flex-direction: column;
  justify-content: center;
  //align-items: center;
  //align-content: center;
  height: 100%;
  min-height: 100%;
  bottom: auto;
  display: none;
  color: #253a95;

  h2 {
    font-size: 4.5rem;
    font-weight: bold;
  }

  h3 {
    font-size: 2rem;
    font-weight: 400;
  }
}

.carousel .carousel-item-1 .desc-wrap,
.carousel .carousel-item-3 .desc-wrap{
  color: #fff;
}

.carousel-indicators {

  margin-left: auto;
  margin-right: auto;
  padding-right: 0;

  &.active {
    background: none;
  }

  li {
    width: auto;
    height: auto;
    border: 1px solid #cecdcd;
    background: none;
    border-radius: 20px;

    span {
      background-color: #cecdcd;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      display: block;
      margin: 1px;

    }
  }
}

.box-footer {

  .menu {

    .dropdown-menu  {
      display: none !important;
    }

    .dropdown-toggle::after {
      display: none;
    }

    .nav-item {
      border-bottom: 1px dashed #717172;

      &:last-child {
        border-bottom: none;
      }


    }

    .nav-link {
      padding: 0.35rem 1rem;
      text-transform: uppercase;
      color: #b9b9b9;
      //position: relative;

      &:before {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        padding-right: 10px;
        font-size: 0.8rem;
        padding-bottom: 2px;
        vertical-align: middle;
        font-family: "FontAwesome"; content: "\f105";
      }

      &:hover {
        color: #f3f3f3;
      }
    }


  }

  .fa {
    width: 40px;
    height: 40px;
    color: #fff;
    margin-right: 10px;
    border-radius: 3px;
    font-size: 1.4rem;
    padding-top: 8px;

    &:hover  {
      opacity: 0.8;
    }
  }
  .fa-facebook  {
    background-color: #1c6db1;
  }
  .fa-twitter  {
    background-color: #39a1f1;
  }
  .fa-youtube  {
    background-color: #9c9c9e;
  }

}

/**
Page
 */
.top-wrap {
  position: relative;

  &:after {
    background: url("../images/page_shadow.png") no-repeat bottom center;
    position: absolute;
    bottom: -30px;
    height: 30px;
    width: 100%;
    z-index: 0;
    content: "";
  }
}

#top-section  {
  background: url("../images/page_top_bg.jpg") no-repeat;
  min-height: 210px;
  background-position: center;
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;


  .titlewrap  {
    position: relative;
    z-index: 10;
    position: relative;
    padding-bottom: 14px;

    &:before {
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 150px;
      height: 3px;
      background-color: #fff;
      content: "";
    }
    h2  {
      margin-bottom: 0;
      font-size: 1.2rem;
    }
  }


}

/**
  breadcrumb
 */
.breadcrumb-wrap  {
  background-color: #f7f8fa;
  border-bottom: 1px solid #d9dee2;
  margin-bottom: 1rem;

  .breadcrumb {
    background: none;
    margin-bottom: 0;
    border-radius: 0;
    color: #505055;

    .breadcrumb-item + .breadcrumb-item {
      padding-left: 0.8rem;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      content: "›";
      font-size: 1.5rem;
      line-height: 1.2rem;
      padding-right: 0.8rem;
    }

    .arrow  {display: none}

    .fa   {
      padding: 0 0.5rem 0 0;
    }

    a   {
      color: #505055;
    }
  }
}

/**
uklad1 sidebox
 */
.sidebar-column {

  .box  {
    margin-bottom: 4rem;
  }

  .contact-icon {

    ul {
      list-style: none;
      padding-left: 0;

      li {
        background-color: #f7f8fa;
        font-size: 0.9rem;
        width: 100%;
        vertical-align: middle;
        border-top: 1px solid #dedfe1;
        border-right: 1px solid #dedfe1;

        display: flex;
        align-items: center;

        &:last-child  {
          border-bottom: 1px solid #dedfe1;
        }

        span {
          padding: 10px 16px;
        }

        img {
          padding: 16px 6px;
          float: left;
          min-height: 72px;

        }
      }
    }

  }
}


/**
#form-contact-wrap
 */
#form-contact-wrap {

  background-color: #f7f8fa;
  border: 1px solid #d9dee2;
  padding: 2.5rem 2rem;
  font-size: 0.95rem;

  .form-control {
    border-radius: 0;
    font-size: 1.1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .form-group-rodo  {
    padding-top: 2rem;
  }

  .captcha  {
    border: 1px solid #d9dee2;
  }

  .btn  {
    border-radius: 0;
    float: right;
    background: #e44248;
    background: -moz-linear-gradient(top, #e44248 14%, #c4161c 98%);
    background: -webkit-linear-gradient(top, #e44248 14%, #c4161c 98%);
    background: linear-gradient(to bottom, #e44248 14%, #c4161c 98%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e44248', endColorstr='#c4161c',GradientType=0 );

    &:hover {
      background: #cd4147;
      background: -moz-linear-gradient(top, #cd4147 14%, #af161c 98%);
      background: -webkit-linear-gradient(top, #cd4147 14%, #af161c 98%);
      background: linear-gradient(to bottom, #cd4147 14%, #af161c 98%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cd4147', endColorstr='#af161c',GradientType=0 );
    }
  }
}


/**
  news-listing
 */
.news-listing {

  .item {
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #e5e5e5;


  }


  .item:last-child  {
    border: none;
  }

  .title  {
    font-size: 1.2rem;
    font-family: 'Lato';
    text-transform: none;
    line-height: 1.6rem;
    margin-bottom: 1rem;
  }

  .t_main {
    font-size: 0.9rem;
    line-height: 1.4rem;
    color: #8e8e90;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: .8rem;
    margin-bottom: .8rem;
  }

  .t_category {
    display: none;
  }

  .news-date  {
    color: #8e8e90;
    font-size: 0.9rem;
    float: left;
    margin-top: 0.1rem;
  }

  .news-more {
    padding: .40rem 1.5rem;
    float: right;
    font-weight: normal;
    font-family: 'Lato';
    font-size: 0.95rem;
  }
}

.news-single  {

  .news-date  {
    margin-bottom: 1rem;
  }

  .title  {
    margin-bottom: 1.5rem;
  }

  .thumbwrap  {
    margin-bottom: 1rem;
  }

  .t_category {
    display: none;
  }
}

/**
  gallery-wrap
 */
.gallery-wrap {
  justify-content: center;
  text-align: center;

  .item {
    margin-bottom: 1rem;
    padding-left: 10px;
    padding-right: 10px;


    .img-wrap {
      position: relative;
    }

    .hover-mask {
      background: rgba(0,0,0,0.3);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      overflow:visible;
      transition: all 0.4s ease-in-out;

    }

    a:hover .hover-mask   {
      opacity: 1;
    }
  }
}


/**

  CSS Termo

 */
body  {
  background: url("../images/wrap_bg.jpg") #f1f1f1 center top;
}

#header-box {
  font-size: 13px;
  font-family: 'Montserrat';
  font-weight: 400;

  .fa {
    font-size: 16px;
    margin-right: 4px;
  }

  .box  {
    margin-bottom: 0.75rem;

    &:first-child {
      border-bottom: 1px dashed #c1c1c1;
    }

    ul {
      padding-left: 0;
    }

    li  {
      display: inline-block;
      margin-left: 20px;
    }
    .btn  {
      border: none;
      padding: 0.20rem 0.75rem 0.15rem 0.75rem;
      font-weight: 500;
      color: #f1f1f1;
    }
    .btn-fb {
      background: #385da5;
      background: -moz-linear-gradient(top, #385da5 0%, #3c5fa7 100%);
      background: -webkit-linear-gradient(top, #385da5 0%,#3c5fa7 100%);
      background: linear-gradient(to bottom, #385da5 0%,#3c5fa7 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#385da5', endColorstr='#3c5fa7',GradientType=0 );

      &:hover {
        background-color: #3b4b91;
      }
    }
    .btn-blog {
      background: #e34147;
      background: -moz-linear-gradient(top, #e34147 0%, #c4171d 100%);
      background: -webkit-linear-gradient(top, #e34147 0%, #c4171d 100%);
      background: linear-gradient(to bottom, #e34147 0%, #c4171d 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e34147', endColorstr='#c4171d', GradientType=0);
      padding: 0.20rem 1.00rem 0.15rem 1.00rem;

      &:hover {
        background-color: #a5171d;
      }
    }
  }

  a {
    color: inherit;
  }
}

.titlewrap  {
  background: url("../images/titlewrap_border.jpg") no-repeat left bottom;
  margin-bottom: 2.5rem;

  h2  {
    font-size: 28px;
    font-weight: 600;
    padding-bottom: 2rem;
  }
}

ul  {
  color: #76797b;
}


.special-container  {
  border: 1px solid #e0e0e0;
  margin: 30px 0 20px 0;
  padding: 40px 50px;
  position: relative;

  h6 {
    background-color: #fff;
    display: inline-block;
    position: absolute;
    left: 48px;
    top: -16px;
    padding: 5px 18px;
    font-family: 'Lato';
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: none;

  }
}


#content {

  ul {
    padding-left: 0.7rem;

    li {
      list-style: none;
      font-size: 15px;
      line-height: 1.4rem;
      position: relative;
      margin-bottom: 10px;
      padding-left: 2em;

    &:before {
      content: "\2022";
      position: absolute;
      top: 0;
      left: 1em;
      color: #cc0001;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -.5em;
      padding-right: 1.0em;
     }
    }
  }
}

.page-head  {
  padding-left: 10%;

  h2 {
    padding-top: 1.5rem;
    font-size: 2.15rem;
    position: relative;

    &:before  {
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      height: 3px;
      width: 20%;

    }
  }
}

.page-id-3 hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}



/**

  Slider OWL

 */

//.slider-content-wrap-23   {}
#slider-content-wrap-23    {margin: 25px auto 40px; padding: 30px 0 20px 0; background-color: #f1f1f1;}
.owl-carousel      {width: 100%;padding-left: 6%; padding-right: 6%;}
.owl-item .item       {background-color: #fff; padding: 10px; }
.owl-item .item img {opacity: 0.9;}
.owl-item .item:hover img {opacity: 1;}
/*.page .owl-nav  {position: relative;}*/
.owl-theme .owl-nav .owl-prev     {background: url("../images/arrows-owl-left.png");
  position: absolute; top: 0; height: 100%; width: 5%; left: 0; margin: 0; border-radius: 0;
  overflow: hidden; text-indent: -999em; background-position: center; background-repeat: no-repeat;opacity: 0.8;}
.owl-theme .owl-nav .owl-next     {background: url("../images/arrows-owl-right.png");
  position: absolute; top: 0; height: 100%; width: 5%; right: 0; margin: 0; border-radius: 0;
  overflow: hidden; text-indent: -999em; background-position: center; background-repeat: no-repeat; opacity: 0.8;}
.owl-theme .owl-nav .owl-prev:hover    {background-image: url("../images/arrows-owl-left.png");
  background-color: #c0bfbf;background-position: center; background-repeat: no-repeat; opacity: 0.8;}
.owl-theme .owl-nav .owl-next:hover     {background-image: url("../images/arrows-owl-right.png");
  background-color: #c0bfbf;background-position: center; background-repeat: no-repeat; opacity: 0.8;}


/**

  RWD

 */

@media (min-width: 320px) {

}

@media (max-width: 320px) {

  .navbar-brand {
    display: inline-block;
  }
}

@media (min-width: 480px) {
  .carousel .desc-wrap  {
    display: flex;
  }

  .carousel-item-background-image {
    min-height: 400px;
  }

}

@media (max-width: 576px) {
  #main {
    padding-right: 2%;
    padding-left: 2%;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  .page #main {
    padding-top: 2rem;
  }
  #footer .row  {
    padding-left: 2%;
    padding-right: 2%;
    text-align: center;
  }
  #header-box {
    text-align: center;
  }
  .carousel .desc-wrap h2 {
    font-size: 2rem;
  }
  .carousel .desc-wrap h3 {
    font-size: 1rem;
  }
}
@media (min-width: 576px) {

}

@media (max-width: 768px) {
  .header-top-text-img {display: none;}
  .special-container  {padding-left: 2%; padding-right: 2%;}

  #nav  {
    margin-bottom: 1rem;
  }
}

@media (min-width: 768px) {
  .carousel-item-background-image {
    min-height: 600px;
  }
  .carousel  {

    .desc-wrap  {

      h3  {
      font-size: 3.5rem;
      }
    }
  }

  .under-slider {

    padding: 3.8rem 0 2.8rem 0;

    .section-inner  {
      display: flex;
    }
    .under-slider-text  {
      margin-bottom: 0;
    }
  }

  .section-home-contact-data {
    font-size: 18px;

    .itp-col {
      padding: 4rem 1rem 2.5rem 1rem;
    }
  }
  #top-section .titlewrap  h2  {
    font-size: 2rem;
  }


  #cookies .container-fluid {
    height: auto;
  }
}

@media (min-width: 992px) {
  #navbarNav .menu .nav-link  {
    padding-right: 0.65rem;
    padding-left: 0.65rem;
  }

  .home #main .content .col-md-6:last-child {
    padding-left: 3rem;
  }
  .page-sidebar-column #content {
    width: 65%;
  }
  .page-sidebar-column #sidebar-column {
    width: 30%;
    margin-left: 5%;
  }
}

@media (min-width: 1200px) {

}